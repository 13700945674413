import React, { useState, useEffect } from "react";
import { BACKENDAPI } from "../../../config";
import { apiClient } from "../../../utils/apiClient";
import { toast } from "react-toastify";

const ExpensePageComponent: React.FC = () => {
	const [data, setData] = useState<any>([]);

	const [link, setLink] = useState(`${BACKENDAPI}/v1.0/bills`);
	const [nextPageLink, setNextPageLink] = useState("");
	const [prevPageLink, setPrevPageLink] = useState("");

	useEffect(() => {
		loadData(link);
	}, []);
	// pagination required
	const loadData = (link: string) => {
		apiClient()
			.get(link)
			.then((response: any) => {
				console.log(response);
				setData([...data, ...response.data.bills.data]);
				setNextPageLink(response.data.bills.next_page_url);
				setPrevPageLink(response.data.bills.prev_page_url);
			})
			.catch((error) => {
				console.log(error.response);
			});
	};

	const [search,setSearch] = useState({
		key:"",
		from:"",
		to:""
	})

	const printfunction = () => {
		window.print();
	}

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		if(e.target.value) {
		setSearch({...search,key:e.target.value})
			apiClient()
				.get(`${BACKENDAPI}/v1.0/bills?search=${e.target.value}&&from=${search.from}&&to=${search.to}`)
				.then((response: any) => {
					
					setData([...response.data.bills.data]);
				setNextPageLink(response.data.bills.next_page_url);
				setPrevPageLink(response.data.bills.prev_page_url);
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
	
		apiClient()
			.get(`${BACKENDAPI}/v1.0/bills`)
			.then((response: any) => {
				
				setData([...response.data.bills.data]);
				setNextPageLink(response.data.bills.next_page_url);
				setPrevPageLink(response.data.bills.prev_page_url);
				
			})
			.catch((error) => {
			
				console.log(error.response);
			});
		}

	}

	const handleFromDateSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		if(e.target.value) {
		
			setSearch({...search,from:e.target.value})
			apiClient()
				.get(`${BACKENDAPI}/v1.0/bills?search=${search.key}&&from=${e.target.value}&&to=${search.to}`)
				.then((response: any) => {
					
					setData([...response.data.bills.data]);
				setNextPageLink(response.data.bills.next_page_url);
				setPrevPageLink(response.data.bills.prev_page_url);
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
	
		apiClient()
			.get(`${BACKENDAPI}/v1.0/bills`)
			.then((response: any) => {
				
				setData([...response.data.bills.data]);
				setNextPageLink(response.data.bills.next_page_url);
				setPrevPageLink(response.data.bills.prev_page_url);
				
			})
			.catch((error) => {
			
				console.log(error.response);
			});
		}

	}
	const handleToDateSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		if(e.target.value) {
			setSearch({...search,to:e.target.value})
			apiClient()
				.get(`${BACKENDAPI}/v1.0/bills?search=${search.key}&&from=${search.from}&&to=${e.target.value}`)
				.then((response: any) => {
					
					setData([...response.data.bills.data]);
				setNextPageLink(response.data.bills.next_page_url);
				setPrevPageLink(response.data.bills.prev_page_url);
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
	
		apiClient()
			.get(`${BACKENDAPI}/v1.0/bills`)
			.then((response: any) => {
				
				setData([...response.data.bills.data]);
				setNextPageLink(response.data.bills.next_page_url);
				setPrevPageLink(response.data.bills.prev_page_url);
				
			})
			.catch((error) => {
			
				console.log(error.response);
			});
		}

	}


	return (
		<>	
		<div className="row">
		<div className="col-4 ">

		<input type="text" className="form-control" onChange={handleSearch}/>
	
		</div>
		<div className="col-md-3">
			<label htmlFor="from" className="form-label">
				 from
			</label>
			<input
				type="date"
				className="form-control"
				id="from"
				name="from"
				onChange={handleFromDateSearch}
				
			/>

		</div>
		<div className="col-md-3">
			<label htmlFor="from" className="form-label">
				 to
			</label>
			<input
				type="date"
				className="form-control"
				id="from"
				name="from"
				onChange={handleToDateSearch}
				
			/>

		</div>

		<div className="col-md-2">
			<button onClick={printfunction} className="btn btn-primary">print</button>
		</div>


	</div>
			<table className="table">
				<thead>
					<tr>
						<th scope="col">Expense Type</th>
						<th scope="col">Note</th>
						<th scope="col">Date</th>
			
						<th scope="col">Category</th>
						<th scope="col">Amount</th>
						
			
					</tr>
				</thead>

				{data.length ? (
					<tbody>
						{data.map((el: any) => {
							return (
								<tr key={el.id}>
									<td>{el.wing.name}</td>
									<td>{el.vendor}</td>
									<td>{el.bill_date}</td>
									<td>{el.category}</td>
									<td>{el.order_number}</td>
						
								</tr>
							);
						})}
					</tbody>
				) : null}
			</table>
			<div className="text-center">
				{nextPageLink ? (
					<button
						className="btn btn-primary"
						onClick={() => {
							loadData(nextPageLink);
						}}>
						Load More ...
					</button>
				) : data.length ? (
					prevPageLink ? (
						"No more data to show"
					) : (
						""
					)
				) : (
					"No data to show"
				)}
			</div>
		</>
	);
};

export default ExpensePageComponent;
