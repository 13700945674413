import { NavInterface } from "../interfaces/AdminSideBarInterface";
export const adminSideBarData: NavInterface[] = [
	{
		name: "Master Setup",

		list: [
			{
				name: "Create Wing",
				link: "/admin/wings/create",
				permissions: [],
			},
			{
				name: "Wings",
				link: "/admin/wings",
				permissions: [],
			},
			{
				name: "Create Bank",
				link: "/admin/banks/create",
				permissions: ["no"],
			},
			{
				name: "Banks",
				link: "/admin/banks",
				permissions: ["no"],
			},
		],
	},
	{
		name: "User Management",
		list: [
			{
				name: "Create User",
				link: "/admin/users/create",
				permissions: [],
			},
			{
				name: "Users",
				link: "/admin/users",
				permissions: [],
			},
			{
				name: "Create Role",
				link: "/admin/roles/create",
				permissions: [],
			},
			{
				name: "Roles",
				link: "/admin/roles",
				permissions: [],
			},
		],
	},
	{
		name: "Product",
		list: [
			{
				name: "Add Product",
				link: "/admin/products/create",
				permissions: ["no"],
			},
			{
				name: "List Products",
				link: "/admin/products",
				permissions: ["no"],
			},

			{
				name: "Brands",
				link: "/admin/brands",
				permissions: ["no"],
			},
		],
	},
	{
		name: "Requisition",
		list: [
			{
				name: "Create Requisition",
				link: "/admin/requisitions/create",
				// permissions: ["create requisition"],
				permissions: ["no"],
			},
			{
				name: "Requisitions",
				link: "/admin/requisitions",
				// permissions: [
				// 	"approve requisition",
				// 	"cancel requisition",
				// 	"create requisition",
				// ],
				permissions: ["no"],
			},
			{
				name: "Requisitions Return",
				link: "/admin/requisitions/return",
				// permissions: ["approve requisition", "cancel requisition"],
				permissions: ["no"],
			},

			{
				name: "Requisitions Report",
				link: "/admin/requisitions/report",
				// permissions: ["approve requisition", "cancel requisition"],
				permissions: ["no"],
			},
		],
	},
	{
		name: "Parchase",
		list: [
			{
				name: "Parchase Create",
				link: "/admin/parchases/create",
				// permissions: ["create purchase",],
				permissions: ["no"],
			},
			{
				name: "Purchases",
				link: "/admin/parchases",
				// permissions: ["purchase return", "create purchase"],
				permissions: ["no"],
			},
			{
				name: "Purchases Return",
				link: "/admin/parchases/return",
				// permissions: ["purchase return", "cancel requisition"],
				permissions: ["no"],
			},

			{
				name: "Purchases Report",
				link: "/admin/parchases/report",
				// permissions: ["purchase return"],
				permissions: ["no"],
			},
		],
	},
	{
		name: "Income",
		list: [
			{
				name: "Revenue Create",
				link: "/admin/revenues/create",
				permissions: [],
			},
			{
				name: "List Revenue",
				link: "/admin/revenues",
				permissions: [],
			},
			{
				name: "Credit Note Create",
				link: "/admin/credits/create",
				// permissions: ["add credit voucher"],
				permissions: ["no"],
			},
			{
				name: "List Credit Note",
				link: "/admin/credits",
				// permissions: ["voucher approval", "add credit voucher"],
				permissions: ["no"],
			},
			{
				name: "Income Report",
				link: "/admin/income/report",
				permissions: [],
			},
		],
	},
	{
		name: "Expense",
		list: [
			{
				name: "Bill Create",
				link: "/admin/bills/create",
				permissions: [],
			},
			{
				name: "Bill",
				link: "/admin/bills",
				permissions: [],
			},

			{
				name: "Payment Create",
				link: "/admin/payments/create",
				// permissions: ["add payment"], 
				permissions: ["no"],
			},
			{
				name: "Payment",
				link: "/admin/payments",
				// permissions: ["approve payment", "add payment"],
				permissions: ["no"],
			},
			{
				name: "Debit Note Create",
				link: "/admin/debitNotes/create",
				// permissions: ["add debit voucher"], 
				permissions: ["no"],
			},
			{
				name: "Debit Note",
				link: "/admin/debitNotes",
				// permissions: ["approve voucher", "add debit voucher"],
				permissions: ["no"],
			},
			{
				name: "Expense Report",
				link: "/admin/expense/report",
				permissions: [],
			},
		],
	},

	{
		name: "Report",
		list: [
			{
				name: "Income Report",
				link: "/report/income",
				permissions: [],
			},
			{
				name: "Expence Report",
				link: "/report/expense",
				permissions: [],
			},
			{
				name: "Income Vs Expense Report",
				link: "/report/expense-vs-income",
				permissions: [],
			},
			
		],
	},






	{
		name: "Balance",
		list: [
			{
				name: "Bank Balance",
				link: "/admin/bank/balance",
				permissions: [],
			},
			// {
			// 	name: "Balance Transfer",
			// 	link: "/admin/bank/transfer",
			// 	permissions: ["transfer fund"],
			// },
			// {
			// 	name: "Balance Transfer History",
			// 	link: "/admin/bank/transfer/history",
			// 	permissions: [],
			// },
		],
	},
	{
		name: "Double Entry",
		list: [
			{
				name: "Create Account",
				link: "/admin/chart-of-account/create",
				permissions: ["no"],
			},
			{
				name: "Chart of Accounts",
				link: "/admin/chart-of-account/list",
				permissions: ["no"],
			},
		],
	},
];
