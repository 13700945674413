import React from "react";
import AdminPageComponent from "../../components/PageComponent/AdminPageComponent";

const Admin: React.FC = () => {
	return (
		<AdminPageComponent>
			<main id="main" className="main">
				
				{/* End Page Title */}
			
			</main>
		</AdminPageComponent>
	);
};

export default Admin;
