import React, { useState, useEffect } from "react";
import { BACKENDAPI } from "../../../config";
import { apiClient } from "../../../utils/apiClient";
import { toast } from "react-toastify";

const ExpenseVsIncomePageComponent: React.FC = () => {
	const [data, setData] = useState<any>([]);

	const [link, setLink] = useState(`${BACKENDAPI}/v1.0/income-vs-expense`);
	

	useEffect(() => {
		loadData(link);
	}, []);
	// pagination required
	const loadData = (link: string) => {
		apiClient()
			.get(link)
			.then((response: any) => {
				console.log(response);
				setData([...data, ...response.data]);
			
			})
			.catch((error) => {
				console.log(error.response);
			});
	};

	const [search,setSearch] = useState({
		key:"",
		from:"",
		to:""
	})

	const printfunction = () => {
		window.print();
	}



	const handleFromDateSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		if(e.target.value) {
		
			setSearch({...search,from:e.target.value})
			apiClient()
				.get(`${BACKENDAPI}/v1.0/income-vs-expense?search=${search.key}&&from=${e.target.value}&&to=${search.to}`)
				.then((response: any) => {
					
					setData([...response.data]);
			
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
	
		apiClient()
			.get(`${BACKENDAPI}/v1.0/income-vs-expense`)
			.then((response: any) => {
				
				setData([...response.data]);
				
				
			})
			.catch((error) => {
			
				console.log(error.response);
			});
		}

	}
	const handleToDateSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		if(e.target.value) {
			setSearch({...search,to:e.target.value})
			apiClient()
				.get(`${BACKENDAPI}/v1.0/income-vs-expense?from=${search.from}&&to=${e.target.value}`)
				.then((response: any) => {
					
					setData([...response.data]);
				
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
	
		apiClient()
			.get(`${BACKENDAPI}/v1.0/income-vs-expense`)
			.then((response: any) => {
				
				setData([...response.data]);
			
				
			})
			.catch((error) => {
			
				console.log(error.response);
			});
		}

	}


	return (
		<>	
		<div className="row">
		
		<div className="col-md-3">
			<label htmlFor="from" className="form-label">
				 from
			</label>
			<input
				type="date"
				className="form-control"
				id="from"
				name="from"
				onChange={handleFromDateSearch}
				
			/>

		</div>
		<div className="col-md-3">
			<label htmlFor="from" className="form-label">
				 to
			</label>
			<input
				type="date"
				className="form-control"
				id="from"
				name="from"
				onChange={handleToDateSearch}
				
			/>

		</div>

		<div className="col-md-2">
			<button onClick={printfunction} className="btn btn-primary">print</button>
		</div>


	</div>
			<table className="table">
				<thead>
					<tr>
						<th scope="col">Income</th>
						<th scope="col">Expense</th>
						<th scope="col">Revenue</th>
			
						
			
					</tr>
				</thead>

				{data.length ? (
					<tbody>
						{data.map((el: any) => {
							return (
								<tr >

                                 <td>{el.bills}</td>
								 <td>{el.revenues}</td>
								 <td>{el.revenues - el.bills}</td>
									{/* <td>{el.wing.name}</td>
									<td>{el.vendor}</td>
									<td>{el.bill_date}</td>
									<td>{el.category}</td>
									<td>{el.order_number}</td> */}
						
								</tr>
							);
						})}
					</tbody>
				) : null}
			</table>
			
		</>
	);
};

export default ExpenseVsIncomePageComponent;
